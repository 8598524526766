import React, { useEffect, useState } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Material UI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// Axios API
import axiosInstance from "../utils/axiosInstance";

// Tabs
import { CompanyTabPanel, a11yCompanyProps } from '../components/company/CompanyTabPanel';
import AddCompanyForm from '../components/company/AddCompanyForm';
import UpdateCompanyForm from '../components/company/UpdateCompanyForm';
import RemoveCompanyForm from '../components/company/RemoveCompanyForm';


/**
    * Company Component
    * 
    * This component handles the CRUD operations for companies, including adding, 
    * updating, and removing companies from the database. It utilizes Material UI 
    * components for a visually appealing and user-friendly interface.
    * 
    * @param {Number} activeCrud The index of the currently active CRUD tab.
    * @returns {JSX.Element} The rendered Company Component.
*/
function Company({ activeCrud }) {
    const [loading, setLoading] = useState(true);

    const [, GetCompanies] = useState([]);
    const [, GetCompaniesCount] = React.useState(0);

    const [, GetFacilities] = useState([]);

    const [companyTab, setCompanyTab] = React.useState(0);

    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });



    }, [GetCompanies, GetFacilities])



    /**
        * Sets the active tab
        * @param {Event} e The event object.
        * @param {Number} newValue The index of the tab.
        * @returns {Void}
    */
    const handleCompanyCrud = (event, newValue) => {
        setCompanyTab(newValue);
    };



    return (
        <Container>
            {activeCrud === 0 && <Box sx={{ width: '100%' }}>
                <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <Tabs
                        variant="fullWidth"
                        value={companyTab}
                        onChange={handleCompanyCrud}
                        aria-label="company tabs">
                        <Tab
                            label="ADD Company"
                            icon={<AddCircleOutlineIcon />} {...a11yCompanyProps(0)} />
                        <Tab
                            label="Update Company"
                            icon={<EditIcon />} {...a11yCompanyProps(1)} />
                        <Tab
                            label="REMOVE Company"
                            sx={{
                                color: 'primary', // Text color of the tab
                                '&.Mui-selected': {
                                    color: 'error', // Text color of the tab when selected
                                },
                                '& .MuiTab-indicator': {
                                    backgroundColor: 'error', // Indicator color of the tab
                                },
                            }}
                            icon={<RemoveCircleOutlineIcon />} {...a11yCompanyProps(2)} />
                    </Tabs>
                </Box>
                <CompanyTabPanel
                    value={companyTab}
                    index={0}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AddCompanyForm />
                    )}
                </CompanyTabPanel>
                <CompanyTabPanel
                    value={companyTab}
                    index={1}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <UpdateCompanyForm />
                    )}
                </CompanyTabPanel>
                <CompanyTabPanel
                    value={companyTab}
                    index={2}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <RemoveCompanyForm />
                    )}

                </CompanyTabPanel>
            </Box>}
        </Container>
    )
}

export default Company;