import React, { useEffect, useState } from 'react';

// Material UI Components
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Material UI Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SettingsIcon from '@mui/icons-material/Settings';
import ShowChartIcon from '@mui/icons-material/ShowChart';

// Material Styles
import { styled, useTheme } from '@mui/material/styles';

// React Routes
import { Link, Outlet, useNavigate } from 'react-router-dom';

// React Redux
import { useSelector } from "react-redux";

// Images
import cstLogo from '../images/cst_logo_invert.png';
// import { googleLogout } from '@react-oauth/google';
import axiosInstance from '../utils/axiosInstance';

// import Cookies from 'js-cookie';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    backround: "#0c0c17",
    backgroundColor: "#0c0c17",
    color: "white",
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backround: "#0c0c17",
    backgroundColor: "#0c0c17",
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

/**
    * CustomerDrawer Component
    * @param {}
    * @returns {JSX.Element} The rendered CustomerDrawer Component.
*/
export default function CustomerDrawer() {
    const echoUser = useSelector((state) => state.echoUser);
    const [activeTab, setActiveTab] = React.useState('')

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    /**
        * Open Drawer Menu
        * @param {} 
        * @returns {Void}
    */
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    /**
        * Drawer Collapse
        * @param {} 
        * @returns {Void} 
    */
    const handleDrawerClose = () => {
        setOpen(false);
    };

    /**
        * Drawer Navigation Tabs
        * @param {String} tab Sets the Link Tool Tab.
        * @returns {Void} 
    */
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const navigate = useNavigate();

    const handleLogout = async () => {

        try {
            // googleLogout();

            // const response = await axiosInstance.post('/auth/logout');

            // if (response.ok) {
            // Clear localStorage
            // localStorage.clear();
            // localStorage.removeItem('jwtToken');
            // Cookies.remove('customerToken')
            // Cookies.remove('refreshToken')

            // Optionally, redirect the user to the login page or home page
            navigate('/signin');
            window.location.reload(true);
            // } else {
            // console.error('Logout failed');
            // }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const [avatarUrl, setAvatarUrl] = useState('');


    useEffect(() => {

        const fetchAvatar = async () => {
            try {
                const response = await axiosInstance.get(`/customers/profile-pictures/${echoUser?.profile?.avatar.filename}`, {
                    responseType: 'blob' // Important for handling binary data
                });
                const imageUrl = URL.createObjectURL(response.data);
                setAvatarUrl(imageUrl);
            } catch (error) {
                console.error('Error fetching avatar:', error);
            }
        };

        fetchAvatar();
        // try{
        //     const res = axiosInstance.get(`/customers/profile-pictures/${echoUser?.profile?.avatar}`);
        //     console.log(res)
        // } catch (err) {
        //     console.log(err)
        // }

    }, [])

    return (
        <Box
            sx={{
                display: 'flex'
            }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    background: "#0c0c17"
                }}>
                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        role='button'
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            mr: 2, ...(open && {
                                display: 'none'
                            })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Button
                        sx={{ padding: "8px" }}
                        component={Link}
                        to="/">
                        <img
                            src={cstLogo}
                            alt="CST Logo"
                            width="150" />
                    </Button>
                    <Typography
                        variant="h3"
                        noWrap
                        role="heading"
                        component="div"
                        sx={{
                            fontWeight: 600,
                        }}>
                        CST Echo
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                anchor="left"
            >
                <DrawerHeader>
                    <Box sx={{
                        padding: "8px 8px 1px 8px",
                        overflow: "hidden",
                    }}>
                        <IconButton
                            onClick={handleDrawerClose}
                            role="button"
                            aria-label='close drawer'
                            sx={{
                                float: "right",
                                opacity: open ? 1 : 0,
                                color: "white"
                            }}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                        <Avatar
                            alt={echoUser?.profile?.name}
                            src={avatarUrl}
                            sx={{
                                ml: 1,
                                width: open ? "60px" : "0px",
                                height: open ? "60px" : "0px",
                                opacity: open ? 1 : 0
                            }} />
                        <Typography
                            variant="h4"
                            sx={{
                                opacity: open ? 1 : 0,
                                fontWeight: 800
                            }} >
                            {echoUser?.profile?.name}

                        </Typography>
                        <Tooltip title={echoUser?.profile?.email}>
                            <Typography
                                variant="p"
                                sx={{ opacity: open ? 1 : 0 }}>
                                {echoUser?.profile?.email}

                            </Typography>
                        </Tooltip>

                    </Box>

                </DrawerHeader>
                <Box
                    sx={{
                        marginTop: '40px',
                        overflowX: "hidden",
                        overflowY: "auto",
                    }}>
                    <Divider sx={{ opacity: "0.6", bgcolor: "#49494e" }} />

                    <List >
                        <Link to="/echo/home">
                            <ListItem key="Home"
                                disablePadding sx={{
                                    display: 'block',
                                    color: "#9a9a9a",
                                    padding: "0px 16px",
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 2,

                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',

                                    }}
                                    selected={activeTab === 'Home'}
                                    onClick={() => handleTabClick('Home')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: "white"
                                        }}>
                                        <DashboardIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            lineHeight: '2.0'
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Home' ? '600' : '400',
                                                fontSize: ".9rem !important"
                                            }}
                                        >Home</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>


                        <Link to="/echo/monitor">
                            <ListItem
                                key="Monitor"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#9a9a9a",
                                    padding: "0px 16px",

                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}

                                    selected={activeTab === 'Monitor'}
                                    onClick={() => handleTabClick('Monitor')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: "white"
                                        }}>
                                        <MonitorHeartIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Monitor' ? '600' : '400',
                                                fontSize: ".9rem !important",
                                            }}
                                        >
                                            Monitor
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>

                        <Link to="/echo/events">
                            <ListItem
                                key="events"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#9a9a9a",
                                    padding: "0px 16px",

                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}

                                    selected={activeTab === 'Events'}
                                    onClick={() => handleTabClick('Events')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: "white"
                                        }}>
                                        <EventNoteIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Events' ? '600' : '400',
                                                fontSize: ".9rem !important",
                                            }}
                                        >
                                            Events
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>

                        <Link to="/echo/metrics">
                            <ListItem
                                key="metrics"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#9a9a9a",
                                    padding: "0px 16px",

                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}

                                    selected={activeTab === 'Metrics'}
                                    onClick={() => handleTabClick('Metrics')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: "white"
                                        }}>
                                        <ShowChartIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Metrics' ? '600' : '400',
                                                fontSize: ".9rem !important",
                                            }}
                                        >
                                            Metrics
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>

                        <Link to="/echo/logs">
                            <ListItem
                                key="logs"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#9a9a9a",
                                    padding: "0px 16px",

                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}

                                    selected={activeTab === 'Logs'}
                                    onClick={() => handleTabClick('Logs')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: "white"
                                        }}>
                                        <DescriptionIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Logs' ? '600' : '400',
                                                fontSize: ".9rem !important",
                                            }}
                                        >
                                            Logs
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>

                    </List>
                    <Divider sx={{ opacity: "0.6", bgcolor: "#49494e" }} />

                    <List>

                        <Link to="/echo/settings">

                            <ListItem key="Settings"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#9a9a9a",
                                    padding: "0px 16px",
                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Settings'}
                                    onClick={() => handleTabClick('Settings')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: "white"
                                        }}>
                                        <SettingsIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Settings' ? '600' : '400',
                                                fontSize: ".9rem !important",
                                            }}
                                        >
                                            Settings
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>

                        {/* <Link to="/auth/settings"> */}
                        <Divider />

                        <ListItem key="Logout"
                            disablePadding
                            sx={{
                                visibility: open ? "visible" : "hidden",
                                display: 'block',
                                backgroundColor: '#fc5842',
                                color: "white",
                                bottom: 0,
                                position: 'fixed',
                                width: 'auto',
                                borderRadius: '3px',
                                padding: "0px 16px",
                            }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    borderRadius: '15px',
                                }}
                                selected={activeTab === 'Logout'}
                                onClick={() => handleLogout()}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: "white"
                                    }}>
                                    <LogoutIcon fontSize="xl" />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        opacity: open ? 1 : 0
                                    }} >
                                    <Typography
                                        sx={{
                                            fontWeight: activeTab === 'Logout' ? '600' : '400',
                                            fontSize: ".9rem !important",
                                        }}
                                    >
                                        Logout
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        {/* </Link> */}
                    </List>
                </Box>

            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, p: 3,
                    marginTop: "50px",
                    padding: "0px",
                    background: "#141422",
                    height: "100vh"
                }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    );
}