import * as React from 'react';

// Material UI Components
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Material UI Icons
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CollectionsIcon from '@mui/icons-material/Collections';
import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PolicyIcon from '@mui/icons-material/Policy';
// import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SettingsIcon from '@mui/icons-material/Settings';
import TaskIcon from '@mui/icons-material/Task';
import ViewListIcon from '@mui/icons-material/ViewList';

// Material Styles
import { styled, useTheme } from '@mui/material/styles';

// React Routes
import { Link, Outlet, useNavigate } from 'react-router-dom';

// React Redux
import { useSelector } from "react-redux";

// Images
import cstLogo from '../images/cst_logo_invert.png';
import { googleLogout } from '@react-oauth/google';

// Axios Api

// Cookies

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

/**
    * CSTDrawer Component
    * @param {}
    * @returns {JSX.Element} The rendered CSTDrawer Component.
*/
export default function CSTDrawer() {
    const currentUser = useSelector((state) => state.currentUser);
    const [activeTab, setActiveTab] = React.useState('')

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    /**
        * Open Drawer Menu
        * @param {} 
        * @returns {Void}
    */
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    /**
        * Drawer Collapse
        * @param {} 
        * @returns {Void} 
    */
    const handleDrawerClose = () => {
        setOpen(false);
    };

    /**
        * Drawer Navigation Tabs
        * @param {String} tab Sets the Link Tool Tab.
        * @returns {Void} 
    */
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const navigate = useNavigate();

    const handleLogout = async () => {

        try {
            googleLogout();

            // const response = await axiosInstance.post('/auth/logout');

            // if (response.ok) {
            // Clear localStorage
            // localStorage.clear();
            // localStorage.removeItem('jwtToken');
            // Cookies.remove('employeeToken')
            // Cookies.remove('customerToken')
            // Cookies.remove('refreshToken')
            // Cookies.remove('chooseCustomerEmployee')
            // Optionally, redirect the user to the login page or home page
            navigate('/signin');
            window.location.reload(true);
            // } else {
            // console.error('Logout failed');
            // }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex'
            }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    background: "#375fd7"
                }}>
                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        role='button'
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            mr: 2, ...(open && {
                                display: 'none'
                            })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Button
                        sx={{ padding: "8px" }}
                        component={Link}
                        to="/">
                        <img
                            src={cstLogo}
                            alt="CST Logo"
                            width="150" />
                    </Button>
                    <Typography
                        variant="h3"
                        noWrap
                        role="heading"
                        component="div"
                        sx={{
                            fontWeight: 600,
                        }}>
                        CST Hoth
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                anchor="left"
            >
                <DrawerHeader>
                    <Box sx={{
                        padding: "8px 8px 1px 8px",
                        overflow: "hidden"
                    }}>
                        <IconButton
                            onClick={handleDrawerClose}
                            role="button"
                            aria-label='close drawer'
                            sx={{
                                float: "right",
                                opacity: open ? 1 : 0
                            }}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                        <Avatar
                            alt={currentUser?.user?.name}
                            src={currentUser?.user?.picture}
                            sx={{
                                ml: 1,
                                width: open ? "60px" : "0px",
                                height: open ? "60px" : "0px",
                                opacity: open ? 1 : 0
                            }} />
                        <Typography
                            variant="h4"
                            sx={{
                                opacity: open ? 1 : 0,
                                fontWeight: 800
                            }} >
                            {currentUser?.user?.name}

                        </Typography>
                        <Tooltip title={currentUser?.user?.email}>
                            <Typography
                                variant="p"
                                sx={{ opacity: open ? 1 : 0 }}>
                                {currentUser?.user?.email}

                            </Typography>
                        </Tooltip>

                    </Box>

                </DrawerHeader>
                <Box
                    sx={{
                        marginTop: '5px',
                        overflowX: "hidden",
                        overflowY: "auto"
                    }}>
                    <Divider />
                    <ListSubheader
                        component="div"
                        sx={{
                            lineHeight: "35px"
                        }}
                        id="nested-list-subheader">
                        {open ? "Enterprise Asset Management" : "EAM"}
                    </ListSubheader>
                    <List>
                        <Link to="/hoth/overview">
                            <ListItem key="Overview"
                                disablePadding sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 2,

                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',

                                    }}
                                    selected={activeTab === 'Overview'}
                                    onClick={() => handleTabClick('Overview')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <DashboardIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            lineHeight: '2.0'
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Overview' ? '600' : '400',
                                                fontSize: ".85rem !important"
                                            }}
                                        >Overview</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>


                        <Link to="/hoth/inventory-creator">
                            <ListItem
                                key="Inventory Creator"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",

                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}

                                    selected={activeTab === 'Inventory Creator'}
                                    onClick={() => handleTabClick('Inventory Creator')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <ConstructionIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Inventory Creator' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}
                                        >
                                            Inventory Creator
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to="/hoth/inventory-db">

                            <ListItem
                                key="Inventory DB"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",
                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Inventory DB'}
                                    onClick={() => handleTabClick('Inventory DB')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <InventoryIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Inventory DB' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}>Inventory DB</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to="/hoth/visual">
                            <ListItem
                                key="Visual"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",

                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Visual'}
                                    onClick={() => handleTabClick('Visual')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <CollectionsIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Visual' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}
                                        >
                                            Visual
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>

                        <Link to="/hoth/work-orders">

                            <ListItem
                                key="Work Orders"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",
                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Work Orders'}
                                    onClick={() => handleTabClick('Work Orders')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <ViewListIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Work Orders' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}
                                        >
                                            Work Orders
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to="/hoth/tasks">
                            <ListItem key="Tasks"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "2px 16px",
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Tasks'}
                                    onClick={() => handleTabClick('Tasks')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <TaskIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Tasks' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}>Tasks</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        {/* <Link to="/hoth/barcodes">

                            <ListItem
                                key="Barcodes"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",
                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Barcodes'}
                                    onClick={() => handleTabClick('Barcodes')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <QrCodeScannerIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ opacity: open ? 1 : 0 }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Barcodes' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}
                                        >
                                            Barcodes
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link> */}
                    </List>
                    <Divider />
                    <ListSubheader
                        component="div"
                        sx={{
                            lineHeight: "35px"
                        }}
                        id="nested-list-subheader">
                        {open ? "Enterprise Resource Planning" : "ERP"}

                    </ListSubheader>
                    <List>
                        <Link to="/hoth/employees">
                            <ListItem
                                key="Employees"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",
                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Employees'}
                                    onClick={() => handleTabClick('Employees')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <BadgeIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Employees' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}>
                                            Employees
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to="/hoth/department">
                            <ListItem
                                key="Department"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",

                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Department'}
                                    onClick={() => handleTabClick('Department')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <BusinessIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Department' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}>Department</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to="/hoth/locations">
                            <ListItem
                                key="Locations"
                                disablePadding sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",
                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Locations'}
                                    onClick={() => handleTabClick('Locations')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <LocationOnIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Locations' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}>
                                            Locations
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link to="/hoth/policies">

                            <ListItem
                                key="Policies"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",

                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Policies'}
                                    onClick={() => handleTabClick('Policies')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <PolicyIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Policies' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}
                                        >
                                            Policies</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Divider />
                        <Link to="/hoth/settings">

                            <ListItem key="Settings"
                                disablePadding
                                sx={{
                                    display: 'block',
                                    color: "#5d5d5d",
                                    padding: "0px 16px",
                                }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: '15px',
                                    }}
                                    selected={activeTab === 'Settings'}
                                    onClick={() => handleTabClick('Settings')}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}>
                                        <SettingsIcon fontSize="xl" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: open ? 1 : 0
                                        }} >
                                        <Typography
                                            sx={{
                                                fontWeight: activeTab === 'Settings' ? '600' : '400',
                                                fontSize: ".85rem !important",
                                            }}
                                        >
                                            Settings
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </Link>

                        {/* <Link to="/auth/settings"> */}
                        <Divider />

                        <ListItem key="Logout"
                            disablePadding
                            sx={{
                                visibility: open ? "visible" : "hidden",
                                display: 'block',
                                backgroundColor: '#fc5842',
                                color: "white",
                                bottom: 0,
                                position: 'fixed',
                                width: 'auto',
                                borderRadius: '3px',
                                padding: "0px 16px",
                            }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    borderRadius: '15px',
                                }}
                                selected={activeTab === 'Logout'}
                                onClick={() => handleLogout()}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                    <LogoutIcon fontSize="xl" />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        opacity: open ? 1 : 0
                                    }} >
                                    <Typography
                                        sx={{
                                            fontWeight: activeTab === 'Logout' ? '600' : '400',
                                            fontSize: ".85rem !important",
                                        }}
                                    >
                                        Logout
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        {/* </Link> */}
                    </List>
                </Box>

            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, p: 3,
                    marginTop: "10px"
                }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    );
}