import React, { useEffect, useState } from "react";

// Material Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
// import GoogleIcon from '@mui/icons-material/Google';

// JWT
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Axios API
import axiosInstance from "../utils/axiosInstance";

// Google Login API
import { useGoogleLogin } from '@react-oauth/google';

// React Routes
import { useNavigate } from "react-router-dom";

// Images
import cstLogo from '../images/cst_logo_2.png';

// React Redux
import { useDispatch } from "react-redux";
import { updateUser, updateEchoUser } from "../store/actions";
import { styled } from '@mui/material/styles';

// React Router
import { Link, Outlet } from 'react-router-dom';

import echo from '../images/EchoLogo.png';


function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

/**
    * Sign In Component
    * @param {Boolean} setAuthStatus Checks Google Response if User Email exists
    * @returns {JSX.Element} The rendered Sign In Component.
*/
const SignIn = () => {



    // const currentUser = useSelector((state) => state.currentUser);




    return (
        <Container>
            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    padding: 3,
                    marginTop: 4,
                    borderRadius: "20px"
                }}>
                <div>
                    <Box align="center">
                        <Link to="/">
                            <img
                                src={cstLogo}
                                alt="cst logo"
                                width="300" />
                        </Link>
                    </Box>
                    <Grid
                        container
                        spacing={5}>
                        <Grid
                            size={{
                                md: 6,
                                xs: 6
                            }}>

                            <Typography
                                variant="h2"
                                align="center">
                                Customer Login
                            </Typography>

                            <br />
                            <br />
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    marginLeft: "auto",
                                    marginRight: "2rem",
                                    borderRadius: "12px",
                                    backgroundColor: "#1e6ce1",
                                    color: "#fff",
                                    '&:hover': {
                                        backgroundColor: "#1559b3",
                                    },
                                }}
                            >
                                <CardActionArea component={Link} to="/echologin">
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={echo}
                                        alt="Echo App Image"
                                    />
                                    <CardContent
                                        sx={{ textAlign: "center", fontSize: "1rem", fontWeight: "bold" }}
                                    >
                                        Echo App
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            {/* <TextField
                                required
                                fullWidth
                                margin="normal"
                                type="email"
                                label="Email"
                                name="signInEmail"
                                value={signInEmail}
                                error={emailError ? true : false}
                                helperText={emailError}
                                onChange={handleSignInCustomer}
                            />
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                type="password"
                                label="Password"
                                name="signInPassword"
                                value={signInPassword}
                                error={passwordError ? true : false}
                                helperText={passwordError}
                                onChange={handleSignInCustomer}
                            />
                            <Button
                                style={{ marginTop: 40 }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={handleSignInSubmit}>
                                Submit
                            </Button> */}
                        </Grid>
                        <Grid
                            size={{
                                md: 6,
                                xs: 6
                            }}>

                            <Typography
                                variant="h2"
                                align="center">
                                Employee Login
                            </Typography>

                            <br />
                            <br />
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    marginLeft: "auto",
                                    marginRight: "2rem",
                                    borderRadius: "12px",
                                    backgroundColor: "#1e6ce1",
                                    color: "#fff",
                                    '&:hover': {
                                        backgroundColor: "#1559b3",
                                    },
                                }}
                            >
                                <CardActionArea component={Link} to="/hothlogin">
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={cstLogo}
                                        alt="CST App Image"
                                    />
                                    <CardContent
                                        sx={{ textAlign: "center", fontSize: "1rem", fontWeight: "bold" }}
                                    >
                                        CST App
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <br />
                            <br />
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }} >

                                {/* <GoogleSignInButton
                                    fullWidth
                                    size="large"
                                    variant="outlined"
                                    onClick={googleLogin}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                // onClick={googleLogin}
                                // useOneTap 
                                >
                                    <div style={{ marginRight: 16 }}>
                                        <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 48 48"
                                            style={{ display: 'block', width: 24, height: 24 }}
                                        >
                                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                            <path fill="none" d="M0 0h48v48H0z"></path>
                                        </svg>
                                    </div>
                                    <span>Sign in with Google</span>
                                </GoogleSignInButton> */}
                            </Box>
                        </Grid>
                        {/* {token ? (
                        <div>
                            <img src={user.picture} alt="user image" />
                            <h3>User Logged in</h3>
                            <p>Name: {user.name}</p>
                            <p>Email Address: {user.email}</p>
                            <br />
                            <br />
                            <button onClick={logOut}>Log out</button>
                        </div>
                    ) : (
                        <Button size="large" onClick={googleLogin}>Sign in with Google</Button>
                    )} */}
                    </Grid>

                </div>
            </Paper>
        </Container>
    );
};

export default SignIn;