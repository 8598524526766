import React from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import { List, ListItem, ListItemText, Paper } from '@mui/material';

const logData = [
    { id: 1, timestamp: '2024-09-10T10:30:00', message: 'Critical: System down' },
    { id: 2, timestamp: '2024-09-10T11:00:00', message: 'Warning: High CPU usage' },
    { id: 3, timestamp: '2024-09-10T11:15:00', message: 'Error: Failed login attempt' },
    { id: 4, timestamp: '2024-09-10T11:30:00', message: 'Info: Scheduled backup completed' },
    { id: 5, timestamp: '2024-09-10T12:00:00', message: 'Warning: Disk space running low' },
    { id: 6, timestamp: '2024-09-10T12:30:00', message: 'Critical: Database connection lost' },
    { id: 7, timestamp: '2024-09-10T13:00:00', message: 'Info: User session timed out' },
    { id: 8, timestamp: '2024-09-10T13:30:00', message: 'Error: Unable to fetch updates' },
    { id: 9, timestamp: '2024-09-10T14:00:00', message: 'Critical: Unauthorized access detected' },
    { id: 10, timestamp: '2024-09-10T14:30:00', message: 'Info: System reboot completed' },
    { id: 11, timestamp: '2024-09-10T15:00:00', message: 'Warning: High memory usage' },
    { id: 12, timestamp: '2024-09-10T15:30:00', message: 'Error: Application crash reported' },
    { id: 13, timestamp: '2024-09-10T16:00:00', message: 'Info: Network connectivity restored' },
    { id: 14, timestamp: '2024-09-10T16:30:00', message: 'Critical: Power outage detected' },
    { id: 15, timestamp: '2024-09-10T17:00:00', message: 'Info: System maintenance initiated' },
];

/**
 * Logs Component
 * @returns {JSX.Element} The rendered Logs Component.
 */
function Logs() {



    return (
        <Grid
            container
            padding={2}
            spacing={5}
            sx={{
                background: "#141422"
            }}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        background: "#242436",
                        color: "white"
                    }}>
                    <CardContent>
                        <Typography>
                            Logs
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                                System Logs
                            </Typography>
                            <Paper elevation={3} sx={{ background: "#242436", maxHeight: 400, overflow: 'auto', padding: 2 }}>

                                <List>
                                    {logData.map((log) => (
                                        <ListItem key={log.id}>
                                            <ListItemText
                                                sx={{ color: '#fff' }}
                                                primary={log.message}
                                                secondary={<Typography variant="h6" style={{ color: '#ababab' }}>{new Date(log.timestamp).toLocaleString()}</Typography>}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>

            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 6,
                    xs: 6
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>

        </Grid>
    );
}

export default Logs;
