import React, { useEffect, useState } from 'react';

// Material UI Components
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// Axios API
import axiosInstance from "../../../utils/axiosInstance.js";

// Components
import Company from '../../../hooks/Company.jsx';
import Facility from '../../../hooks/Facility.jsx';
import System from '../../../hooks/System.jsx';

/**
    * InventoryCreator Component
    * @returns {JSX.Element} The rendered InventoryCreator Component.
*/
function InventoryCreator() {
    const [, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);

    const [systemList] = useState([]);

    const [system] = React.useState(null);
    const companySearch = [];
    const facilitySearch = [];
    const systemSearch = [];
    const [activeCrud, setActiveCrud] = useState(0);

    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });
    }, [GetCompanies, GetFacilities])

    companyList?.forEach((company) => {
        companySearch.push({ label: company.company_name, companyObject: company })
    })

    facilityList?.forEach((facility) => {
        facilitySearch.push({ label: facility.facility_name, facilityObject: facility })
    })

    systemList?.forEach((facility) => {
        systemSearch.push({ label: facility.system_name, facilityObject: system })
    })

    /**
        * Select Company Facility or System Tab
        * @param {Number} buttonIndex The index of the button that was clicked.
        * @returns {Void} 
    */
    const handleCrudSelection = (buttonIndex) => {
        setActiveCrud(buttonIndex);
    };

    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Inventory Creator
                        </Typography>
                    </CardContent>
                    <CardActions >
                        <ButtonGroup
                            fullWidth
                            sx={{
                                paddingLeft: "150px",
                                paddingRight: "150px"
                            }}
                            variant="outlined"
                            aria-label="Basic button group">
                            <Button
                                onClick={() => handleCrudSelection(0)}
                                variant={activeCrud === 0 ? 'contained' : 'outlined'}>Company</Button>
                            <Button
                                onClick={() => handleCrudSelection(1)}
                                variant={activeCrud === 1 ? 'contained' : 'outlined'}>Facility</Button>
                            <Button
                                onClick={() => handleCrudSelection(2)}
                                variant={activeCrud === 2 ? 'contained' : 'outlined'}>System</Button>
                        </ButtonGroup>
                    </CardActions>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Company activeCrud={activeCrud} />
                    <Facility activeCrud={activeCrud} />
                    <System activeCrud={activeCrud} />
                </Container>
            </Grid>
        </Grid>
    )
}

export default InventoryCreator;