import React, { useEffect } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

// Images
import alfaImage from '../../images/banner/alfa.jpg';
import bacImage from '../../images/banner/bac.jpg';
import clarkImage from '../../images/banner/clark.jpg';
import geaImage from '../../images/banner/gea.jpg';
import gunImage from '../../images/banner/gun.jpg';
import hanImage from '../../images/banner/han.jpg';
import henImage from '../../images/banner/hen.jpg';
import homeImage from '../../images/banner.jpg';
import johnImage from '../../images/banner/john.jpg';
import mayImage from '../../images/banner/may.jpg';
import philImage from '../../images/banner/phil.jpg';
import parImage from '../../images/banner/par.jpg';
import vilImage from '../../images/banner/vil.jpg';
import monitorImage from '../../images/products/echoMonitor.png';
import cstCrane from '../../images/cst_home/cst_crane.jpg';

// Motion API
import { motion, useScroll } from "framer-motion";

function Home() {

    const { scrollY } = useScroll();
    const [isVisible, setIsVisible] = React.useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollY.current >= 100) { // Change the scroll threshold as needed
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        scrollY.on("change", handleScroll);

        return () => {
            scrollY.clearListeners();
        };
    }, [scrollY]);


    return (
        <Box>
            <Box
                style={{
                    width: '100%',
                    background: "#2738aa"
                }}>
                <img
                    style={{
                        width: '40%',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: "center"
                    }}
                    src={homeImage} alt="cst-banner" />
            </Box>

            <Container>
                <Grid
                    container
                    padding={2}
                    spacing={5}>
                    <Grid
                        size={{
                            md: 12
                        }}>
                        <Box
                            sx={{
                                width: 1
                            }}>

                        </Box>
                    </Grid>
                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Card elevation={0} >
                            <CardContent>
                                <Typography
                                    variant="h1"
                                    role="heading"
                                    style={{
                                        fontWeight: 400
                                    }}>
                                    The Central Valley's Authority on Industrial Refrigeration.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card
                            elevation={0}
                            variant="outlined"
                            sx={{
                                borderRadius: "9px"
                            }}>
                            <CardContent>
                                <Typography variant='h5' style={{ fontFamily: "Arimo", fontWeight: 600 }}>
                                    We offer comprehensive solutions which include refrigeration engineering and construction, controls engineering, implementation and construction, regulatory compliance, energy efficiency, as well as a complete service department.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Card
                            elevation={0}
                            variant="outlined"
                            sx={{
                                borderRadius: "9px"
                            }}>
                            <CardContent>
                                <Typography variant="h3" sx={{ fontFamily: "Open Sans", fontWeight: 800 }}>
                                    News Letter
                                </Typography>
                                <Box
                                    sx={{
                                        width: "100%"
                                    }}>
                                    <List
                                        sx={{
                                            width: "100%"
                                        }}>
                                        <ListItem
                                            sx={{
                                                width: "100%"
                                            }}>
                                            <Link href='https://www.coldstoragetech.com/HCS.html'>
                                                <ListItemButton
                                                    sx={{
                                                        width: "100%"
                                                    }}>
                                                    <Typography variant='h6'>
                                                        Hazard Communication Standards Change
                                                    </Typography>
                                                </ListItemButton>
                                            </Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link href='https://www.coldstoragetech.com/CSAT.html'>

                                                <ListItemButton>
                                                    <Typography variant='h6'>
                                                        CSAT Registration for  10K lbs NH3
                                                    </Typography>
                                                </ListItemButton>
                                            </Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link href='https://www.coldstoragetech.com/NewProposedFreonRegs.doc'>
                                                <ListItemButton>
                                                    <Typography variant='h6'>
                                                        New Freon Regulations Proposed
                                                    </Typography>
                                                </ListItemButton>
                                            </Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link href='https://www.coldstoragetech.com/images/114809.pdf'>
                                                <ListItemButton>
                                                    <Typography variant='h6'>
                                                        CPUC allows choice of provider
                                                    </Typography>
                                                </ListItemButton>
                                            </Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link href='https://www.coldstoragetech.com/images/DAtrans.pdf'>

                                                <ListItemButton>
                                                    <Typography variant='h6'>
                                                        Direct Access Q and A
                                                    </Typography>
                                                </ListItemButton>
                                            </Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link href='https://www.coldstoragetech.com/hansenrecall.html'>

                                                <ListItemButton>
                                                    <Typography variant='h6'>
                                                        Hansen recall information
                                                    </Typography>
                                                </ListItemButton>
                                            </Link>
                                        </ListItem>
                                    </List>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box>
                        <motion.div
                            initial={{
                                scale: .8
                            }}
                            animate={{
                                scale: isVisible ? 1 : .8
                            }}
                            transition={{
                                duration: .6
                            }}
                        >
                            <Container>
                                <Typography
                                    variant='h1'
                                    sx={{
                                        fontFamily: 'IBM Plex Sans',
                                        fontWeight: 600,
                                        textAlign: "center",
                                        padding: "8rem 2rem 8rem 2rem"
                                    }}>
                                    <span className='gradientGlow'>
                                        Central California's Ammonia Refrigeration Specialists.
                                    </span>
                                </Typography>
                            </Container>
                        </motion.div>
                    </Box>
                    <Box>
                        <Container>
                            <Grid
                                container
                                spacing={.6}>
                                <Grid
                                    size={{
                                        md: 4,
                                        xs: 12
                                    }}>
                                    <motion.div
                                        initial={{
                                            scale: 0
                                        }}
                                        animate={{
                                            scale: isVisible ? 1 : 0
                                        }}
                                        transition={{
                                            duration: .5
                                        }}
                                        style={{
                                            backgroundColor: "lightblue",
                                            borderRadius: 10,
                                            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
                                        }}
                                    >
                                        <Card sx={{
                                            borderRadius: "9px"
                                        }}>

                                            <Typography
                                                variant='h2'
                                                sx={{
                                                    fontFamily: 'IBM Plex Sans',
                                                    fontWeight: 600,
                                                    textAlign: "center",
                                                    padding: "100px"
                                                }}>
                                                <span className='gradientGlow'>
                                                    50+                            </span>
                                                Companies
                                            </Typography>
                                        </Card>
                                    </motion.div>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 4,
                                        xs: 12
                                    }}>
                                    <motion.div
                                        initial={{
                                            scale: 0
                                        }}
                                        animate={{
                                            scale: isVisible ? 1 : 0
                                        }}
                                        transition={{
                                            duration: .7
                                        }}
                                        style={{
                                            backgroundColor: "lightblue",
                                            borderRadius: 10,
                                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
                                        }}
                                    >
                                        <Card sx={{ borderRadius: "9px" }}>

                                            <Typography
                                                variant='h2'
                                                sx={{
                                                    fontFamily: 'IBM Plex Sans',
                                                    fontWeight: 600,
                                                    textAlign: "center",
                                                    padding: "100px"
                                                }}>
                                                <span className='gradientGlow'>
                                                    300+                            </span>
                                                Facilities
                                            </Typography>
                                        </Card>
                                    </motion.div>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 4,
                                        xs: 12
                                    }}>
                                    <motion.div

                                        initial={{ scale: 0 }}
                                        animate={{ scale: isVisible ? 1 : 0 }}
                                        transition={{ duration: 1.05 }}
                                        style={{
                                            borderRadius: 0,
                                            boxShadow: "9px 0px 5px 0px rgba(0,0,0,0.1)",
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                borderRadius: "9px"
                                            }}>
                                            <Typography variant='h2'
                                                sx={{
                                                    fontFamily: 'IBM Plex Sans',
                                                    fontWeight: 600,
                                                    textAlign: "center",
                                                    padding: "100px"
                                                }}>
                                                <span className='gradientGlow'>
                                                    1000+                            </span>
                                                Systems
                                            </Typography>
                                        </Card>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                </Grid>
            </Container>

            <Box
                style={{
                    width: '100%',   // Full width of the page
                    height: '600px', // Fixed height for the banner (adjust as needed)
                    overflow: 'hidden' // Ensures that the content that exceeds the height will be hidden (cropping effect)
                }}
            >
                <img
                    src={cstCrane}
                    style={{
                        width: '100%',
                        height: '100%', // Ensures the image height fills the container
                        objectFit: 'cover', // Crops the image to maintain aspect ratio while covering the container
                    }}
                    alt="Banner"
                />
            </Box>


            <Box
                sx={{
                    marginTop: "100px",
                    padding: '180px',
                    background: '#1e6ce1'
                }}>
                <Container>
                    <Typography variant="h1" sx={{ color: "#fff", fontFamily: 'IBM Plex Sans' }}>Monitor with <span
                        role="heading"
                        aria-level="1"
                        style={{ fontWeight: 900, fontFamily: 'IBM Plex Sans', letterSpacing: "8px" }}>CST ECHO</span></Typography>

                    <img src={monitorImage} width="100%" alt="monitor" />


                </Container>

            </Box>
            <Container>
                <FormatQuoteIcon sx={{ fontSize: "200px" }} />
                <Box
                    padding={10}
                    sx={{ textAlign: "center" }}>
                    <Typography variant="h1" sx={{ fontFamily: "Arimo" }}>Best Technology</Typography>
                    <Rating name="size-large" defaultValue={5} size="large" />
                </Box>
                <Box
                    padding={10}
                    sx={{ textAlign: "center" }}>
                    <Typography variant="h1">Professional</Typography>
                    <Rating name="size-large" defaultValue={5} size="large" />

                </Box>

            </Container>



            <Container>
                <Box
                    sx={{
                        padding: "100px"
                    }}>
                    <Divider
                        sx={{
                            height: "5px",
                            background: "linear-gradient(to right, #375fd7 50%, #afd5ff 50%)"
                        }}></Divider>
                </Box>
                <Box
                    sx={{
                        marginTop: "50px"
                    }}>
                </Box>
                <Box
                    sx={{
                        marginTop: "10px",
                        padding: "20px"
                    }}>
                    <Box
                        padding={10}>
                        <Typography variant='h1' style={{ fontFamily: "IBM Plex Sans" }}>Partners</Typography>
                    </Box>

                    <Grid
                        container
                        spacing={5}>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{
                                    scale: 0
                                }}
                                animate={{
                                    scale: isVisible ? 1 : 0
                                }}
                                transition={{
                                    duration: 1.4
                                }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={alfaImage} alt="alfa" />
                                </Card>
                            </motion.div>

                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 1.5 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={clarkImage} alt="clark" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 1.6 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={bacImage} alt="bac" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 1.7 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={geaImage} alt="gea" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 1.8 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={gunImage} alt="gun" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 1.9 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={hanImage} alt="han" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 2.5 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={henImage} alt="hen" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 2.6 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={johnImage} alt="john" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 2.7 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={mayImage} alt="may" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 2.8 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={philImage} alt="phil" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 2.9 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={parImage} alt="par" />
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid
                            size={{
                                md: 2,
                                xs: 6
                            }}>
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: isVisible ? 1 : 0 }}
                                transition={{ duration: 3 }}
                                style={{
                                    backgroundColor: "lightblue",
                                    borderRadius: 10,
                                }}
                            >
                                <Card elevation={0}>
                                    <img src={vilImage} alt="vil" />
                                </Card>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
        </Box >
    )
}

export default Home;