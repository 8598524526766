import React, { useState, useEffect } from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

// Axios API
import axiosInstance from "../../../utils/axiosInstance";

// Charts
import OutageChart from '../../../components/apex/OutagesChart';
import AlertsChart from '../../../components/apex/AlertsChart';
import StatusChart from '../../../components/apex/StatusChart';

/**
 * Metrics Component
 * @returns {JSX.Element} The rendered Metrics Component.
 */
function Metrics() {

    const [, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [facilityList, GetFacilities] = useState([]);
    const [systemList] = useState([]);

    const [system] = React.useState(null);

    const companySearch = [];
    const facilitySearch = [];
    const systemSearch = [];

    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                console.log(response.data);
                GetCompanies(response.data);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });
    }, [GetCompanies, GetFacilities])

    companyList?.forEach((company) => {
        companySearch.push(
            {
                label: company.company_name,
                companyObject: company
            })
    })

    facilityList?.forEach((facility) => {
        facilitySearch.push(
            {
                label: facility.facility_name,
                facilityObject: facility
            })
    })

    systemList?.forEach((facility) => {
        systemSearch.push(
            {
                label: facility.system_name,
                facilityObject: system
            })
    })

    return (
        <Grid
            container
            padding={2}
            spacing={5}
            sx={{
                background: "#141422"
            }}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        background: "#242436",
                        color: "white"
                    }}>
                    <CardContent>
                        <Typography>
                            Metrics
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Status
                            </Typography>
                            <Container>
                                <StatusChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>

            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Alerts
                            </Typography>
                            <Container>
                                <AlertsChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 6,
                    xs: 6
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Outages
                            </Typography>
                            <Container>
                                <OutageChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Uptime
                            </Typography>
                            <Typography variant="h5">
                                uptime percentage, total uptime,
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </Grid>
    );
}

export default Metrics;
