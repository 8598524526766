import React, { useEffect, useState } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Material UI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

// Axios API
import axiosInstance from "../utils/axiosInstance";

// Tabs
import { FacilityTabPanel, a11yFacilityProps } from '../components/facility/FacilityTabPanel';
import AddFacilityForm from '../components/facility/AddFacilityForm';
import UpdateFacilityForm from '../components/facility/UpdateFacilityForm';
import RemoveFacilityForm from '../components/facility/RemoveFacilityForm';

/**
    * Facility Component
    * @param {Number} activeCrud The active CRUD operation
    * @returns {JSX.Element} The rendered Facility Component.
*/
function Facility({ activeCrud }) {

    const [loading, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);

    const [systemList] = useState([]);

    const [system] = React.useState(null);


    const [facilityTab, setFacilityTab] = React.useState(0);

    const systemSearch = [];
    const facilitySearch = [];
    const companySearch = [];



    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });
    }, [GetCompanies, GetFacilities])



    companyList?.forEach((company) => {
        companySearch.push({ label: company.company_name, companyObject: company })
    })


    facilityList?.forEach((facility) => {
        facilitySearch.push({ label: facility.facility_name, facilityObject: facility })
    })


    systemList?.forEach((facility) => {
        systemSearch.push({ label: facility.system_name, facilityObject: system })
    })

    /**
        * Sets the active tab to Facility
        * @param {Event} event The event object.
        * @param {Number} newValue The new value of the tab.
        * @returns {}
    */
    const handleFacilityCrud = (event, newValue) => {
        setFacilityTab(newValue);
    };


    return (
        <Container>
            {activeCrud === 1 && <Box
                sx={{
                    width: '100%'
                }}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}>
                    <Tabs variant="fullWidth"
                        value={facilityTab}
                        onChange={handleFacilityCrud}
                        aria-label="facility tabs">
                        <Tab
                            label="ADD Facility"
                            icon={<AddCircleOutlineIcon />} {...a11yFacilityProps(0)} />
                        <Tab
                            label="UPDATE Facility"
                            icon={<EditIcon />} {...a11yFacilityProps(1)} />
                        <Tab
                            label="REMOVE Facility"
                            sx={{
                                color: 'primary', // Text color of the tab
                                '&.Mui-selected': {
                                    color: 'error', // Text color of the tab when selected
                                },
                                '& .MuiTab-indicator': {
                                    backgroundColor: 'error', // Indicator color of the tab
                                },
                            }}
                            icon={<RemoveCircleOutlineIcon />} {...a11yFacilityProps(2)} />
                    </Tabs>
                </Box>
                <FacilityTabPanel
                    value={facilityTab}
                    index={0}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AddFacilityForm />
                    )}

                </FacilityTabPanel>
                <FacilityTabPanel
                    value={facilityTab}
                    index={1}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <UpdateFacilityForm />
                    )}

                </FacilityTabPanel>
                <FacilityTabPanel
                    value={facilityTab}
                    index={2}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <RemoveFacilityForm />
                    )}

                </FacilityTabPanel>
            </Box>}
        </Container>
    )
}

export default Facility;