import React, { useEffect, useState } from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// Axios API
import axiosInstance from "../../../utils/axiosInstance";

// Google Maps API
import { Loader } from "@googlemaps/js-api-loader"

const mapOptions = {
    center: {
        lat: 37.0902,
        lng: -95.7129
    },
    minZoom: 3,
    zoom: 10,
    maxZoom: 15,
    scrollwheel: true
};

const loader = new Loader({
    apiKey: "AIzaSyBLLTDWOA_OcJhiGjuASeVJ4ScFztNx2DQ",
    version: "weekly",
    libraries: ["places"]
    // ...additionalOptions,
});

/**
    * Locations Component
    * @returns {JSX.Element} The rendered Locations Component.
*/
function Locations() {
    const [, setLoading] = useState(true);

    const [facilityList, GetFacilities] = useState([]);

    // async function geocodeAddress(address) {
    //     // Use the Geocoding Service from Google Maps API
    //     const geocoder = new window.google.maps.Geocoder();
    //     return new Promise((resolve, reject) => {
    //         geocoder.geocode({ address }, (results, status) => {
    //             if (status === "OK") {
    //                 if (results[0]) {
    //                     // Resolve with the first result's geometry
    //                     resolve(results[0].geometry.location);
    //                     // console.log(results[0].geometry.location);
    //                 } else {
    //                     reject("No results found");
    //                 }
    //             } else {
    //                 reject(`Geocode was not successful for the following reason: ${status}`);
    //             }
    //         });
    //     });
    // }

    /**
        * Google Maps API Loader
        * 
        * This function initializes the Google Maps API and creates markers for each 
        * facility in the `facilityList` array. It uses the `loader` object to load the 
        * Google Maps API and then creates a map instance with the specified options. 
        * For each facility, it creates a marker at the facility's coordinates (if 
        * available) and adds an info window that displays the facility name and 
        * company name. The map is then centered and zoomed to fit all the markers.
        * 
        * @returns {void}
    */
    loader.load().then(async () => {
        const map = await new window.google.maps.Map(document.getElementById("map"), mapOptions);
        const bounds = await new window.google.maps.LatLngBounds();

        facilityList.map(async (facility) => {
            // console.log({location:facility?.facility_name + " " + facility?.company_name,lat:"" ,lng:""});

            try {
                // const location = await geocodeAddress(facility?.facility_name + " " + facility?.company_name);

                // if (location) {

                if (facility) {
                    const contentString =
                        '<div id="content">' +
                        '<div id="siteNotice">' +
                        "</div>" +
                        `<h1 id="firstHeading" class="firstHeading">${facility?.facility_name}</h1>` +
                        '<div id="bodyContent">' +
                        `<p><b>${facility?.company_name}</b></p>` +
                        "</div>" +
                        "</div>";
                    const infowindow = await new window.google.maps.InfoWindow({
                        content: contentString,
                        ariaLabel: facility?.facility_name,
                    });
                    if (facility?.lat && facility?.long) {
                        var latlng = new window.google.maps.LatLng(facility?.lat, facility?.long);

                        const marker = await new window.google.maps.Marker({
                            position: latlng,
                            map,
                            title: facility?.facility_name,
                        });

                        if (marker?.position) {
                            map.setCenter(marker.getPosition());
                        }


                        marker.addListener("click", () => {
                            infowindow.open({
                                anchor: marker,
                                map,
                            });
                        });
                        // Close infowindow when map is clicked
                        window.google.maps.event.addListener(map, 'click', function () {
                            infowindow.close();
                        });

                        await bounds.extend(marker.getPosition());
                    }
                }
            } catch (error) {
                console.log(error);
            }
        })
        await map.fitBounds(bounds);
        // console.log(markerList);
        // console.log(marker);

    });

    useEffect(() => {

        axiosInstance
            .get(`/facilities`).then(response => {
                // console.log(response.data);
                GetFacilities(response.data);
                console.log("User Fetched and Facilities Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });
    }, [GetFacilities])

    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Locations
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 8,
                    xs: 12
                }}>
                <div id="map"></div>
                {/* {facilityList.map((facilityList) => {
                        return (<div>{facilityList.facility_name}</div>)
                    })} */}
            </Grid>
        </Grid>
    )
}

export default Locations;